import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import {
  AdditionaleItems,
  BillingInfo,
  Home,
  Product,
  ServiceDetails,
  TermsAndCondition,
  Thanks,
} from "../components/pages";
import { BreadCrumb, Header } from "../components/common";
import { useDispatch } from "react-redux";
import { addProductToCart, updateTaxRate } from "../redux/reducers/appreducer";
import {
  getCartLocalStorage,
  getTaxRate,
} from "../actions/localstorage";
import { getToken } from "../api/api";

function ScrollToTop({ children }) {
  const dispatch = useDispatch();

  const location = useLocation();

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  const companyId = searchParams.get('companyId')

  if (companyId) {
    localStorage.setItem('companyId', companyId)
  } else {
    let companyId = localStorage.getItem('companyId');
    if (!companyId) window.location.replace('https://www.curbwaste.com')
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    const cartData = getCartLocalStorage();
    const taxRate = getTaxRate();
    cartData && dispatch(addProductToCart(cartData));
    taxRate && dispatch(updateTaxRate(taxRate));
  }, [location]);

  useEffect(() => {
    getToken();
  }, [])

  return children;
}

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Header />
        <BreadCrumb />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/additional-items" element={<AdditionaleItems />} />
          <Route path="/service-details" element={<ServiceDetails />} />
          <Route path="/payment" element={<BillingInfo />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
