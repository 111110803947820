import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

//import components
import OrderSummary from "../../layouts/BillingInfo/order-summary";
import BillingInformation from "../../layouts/BillingInfo/billing-information";

//import functions
import {
  breadCrumbChange,
  updateListenerAdded,
} from "../../../redux/reducers/appreducer";
import { billingInfoValidation } from "../../../actions/validations";
import {
  addToCartLocalStorage,
  getCartLocalStorage,
  getZipFromLocal,
} from "../../../actions/localstorage";
import { placeOrder } from "../../../actions/commonFunctions";
import { Spinner } from "../../common";

const PaymentComponent = () => {
  const zipData = getZipFromLocal();
  const cartData = getCartLocalStorage();

  const {
    serviceAddress,
    billingInformation: {
      email,
      city,
      address,
      zipCode,
      suite,
      state,
      phoneNumber,
      lastName,
      firstName,
      sameAsService,
      town,
      street,
    },
  } = cartData || { billingInformation: {} };

  const [sameAsTheService, setSameAsTheSevice] = useState(true);
  const listenerAdded = useSelector((state) => state.listenerAdded);
  const [btnDisable, setBtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cardErr, setCardErr] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);

  const engineRef = useRef();

  const [data, setData] = useState({
    firstName,
    lastName,
    address: sameAsTheService ? serviceAddress?.address : address,
    suite: suite ? suite : "",
    city: sameAsTheService ? serviceAddress?.city : city,
    state: sameAsTheService ? serviceAddress?.state : state,
    zipCode: zipCode ? zipCode : zipData?.zipCode,
    phoneNumber: phoneNumber ? phoneNumber : "",
    email: email ? email : "",
    creditCard: "",
    cardExp: "",
    cardCVC: "",
    termsChecked: false,
    formatted_address: null,
    sameAsService: sameAsTheService,
    street: sameAsTheService ? serviceAddress?.street : street,
    town: sameAsTheService ? serviceAddress?.town : town,
  });

  const changeAddressCheck = (e) => {
    const { checked } = e.target;
    setSameAsTheSevice(checked);
    setData((data) => ({
      ...data,
      address: checked ? serviceAddress?.address : address ? address : "",
      city: checked ? serviceAddress?.city : city ? city : "",
      state: checked ? serviceAddress?.state : state ? state : "",
      zipCode: zipCode ? zipCode : zipData?.zipCode,
      street: checked ? serviceAddress?.street : street ? street : "",
      town: checked ? serviceAddress?.checked : checked ? checked : "",
    }));
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(breadCrumbChange(3));
  }, []);

  const handleSubmit = async (values) => {
    const component = engineRef.current;
    !listenerAdded &&
      component.addEventListener("success", async function (card) {
        setCardErr(null);
        const orderResponse = await placeOrder(card.detail);
        if (orderResponse === "Succees") {
          setLoading(false);
          navigate("/thanks");
        } else {
          setLoading(false);
          alert("Something wrong");
        }
      });

    //--> Error handler
    !listenerAdded &&
      component.addEventListener("error", function (e) {
        // console.log("Unable to create card", e.detail);
        setLoading(false);
        setCardErr("Card Information not valid");
      });
    dispatch(updateListenerAdded(true));
    const { address, city, state, zipCode } = data;
    const dataForSend = {
      ...cartData,
      billingInformation: { ...values, address, city, state, zipCode },
    };
    addToCartLocalStorage(dataForSend);

    component.submit();
    setLoading(true);
  };

  const navigateToProduct = () => {
    navigate("/product");
  };

  return (
    <>
      <Formik
        initialValues={{ ...data }}
        validate={(values) => billingInfoValidation(values, sameAsTheService,data)}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="container">
              <div className="form-header mt-3">
                <div className="form-header-col">
                  <div className="form-header-col-inner">
                    <h1 className="form-header-title">Payment </h1>
                    <p className="form-header-subtitle">
                      Enter some final details to complete your order
                    </p>
                  </div>
                </div>
                <div className="form-header-col"></div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <OrderSummary
                    cartData={cartData}
                    navigateToProduct={navigateToProduct}
                  />
                </div>
                <div className="col-md-8">
                  <BillingInformation
                    termsChecked={termsChecked}
                    setTermsChecked={setTermsChecked}
                    cardErr={cardErr}
                    setBtnDisable={setBtnDisable}
                    engineRef={engineRef}
                    changeAddressCheck={changeAddressCheck}
                    data={data}
                    setData={setData}
                    setSameAsTheSevice={setSameAsTheSevice}
                    sameAsTheService={sameAsTheService}
                  />
                  <div className="form-footer">
                    <button
                      disabled={btnDisable || loading || !termsChecked}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {loading ? (
                        <Spinner color={"#FFF"} size={20} />
                      ) : (
                        "Complete Order"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default PaymentComponent;
