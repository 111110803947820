import React from "react";

const Index = ({ size, color }) => {
  const spinnerColor = color ?? "#01aef0";

  return (
    <div
      style={{ color: spinnerColor, width: size ?? 30, height: size ?? 30}}
      className="spinner-border"
      role="status"
    ></div>
  );
};

export default Index;
