export const breadCrumb = [
  {
    name: "Select Your Product",
    url: "/product",
  },
  {
    name: "Additional Items",
    url: "/additional-items",
  },
  {
    name: "Service Details",
    url: "/service-details",
  },
  {
    name: "Payment",
    url: "/payment",
  },
  {
    name: "Complete",
    url: "/thanks",
  },
];

const productData = [
  {
    name: "15 Yard Dumpster",
    weight: "4 Tons",
    price: "575",
    additionalFee: [
      {
        name: "Additional Weight",
        fee: 95,
        feePer: "ton",
      },
      {
        name: "Automotive Tires",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Truck Tires",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Electronics (TVs, Computers, etc)",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Mattresses or Box Spring",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Appliance w/ Freon",
        fee: 10,
        feePer: "ae",
      },
    ],
    prohibitedItems: [
      "Toxic or Hazardous Materials",
      "Medical or Biological Waste",
      "Motor Oil / Antifreeze",
      "Gasoline / Kerosene",
      "Highly Flammable Items",
      "Fire Extinguishers",
      "Pool Chemicals",
      "Liquid Wastes",
      "Solvents",
      "Ammunition",
      "Explosives",
      "Military Ordinance",
      "Radioactive Items ",
      "Equipment Tires",
    ],
  },
  {
    name: "15 Yard Dumpster",
    weight: "4 Tons",
    price: "575",
    additionalFee: [
      {
        name: "Additional Weight",
        fee: 95,
        feePer: "ton",
      },
      {
        name: "Automotive Tires",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Truck Tires",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Electronics (TVs, Computers, etc)",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Mattresses or Box Spring",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Appliance w/ Freon",
        fee: 10,
        feePer: "ae",
      },
    ],
    prohibitedItems: [
      "Toxic or Hazardous Materials",
      "Medical or Biological Waste",
      "Motor Oil / Antifreeze",
      "Gasoline / Kerosene",
      "Highly Flammable Items",
      "Fire Extinguishers",
      "Pool Chemicals",
      "Liquid Wastes",
      "Solvents",
      "Ammunition",
      "Explosives",
      "Military Ordinance",
      "Radioactive Items ",
      "Equipment Tires",
    ],
  },
  {
    name: "15 Yard Dumpster",
    weight: "4 Tons",
    price: "575",
    additionalFee: [
      {
        name: "Additional Weight",
        fee: 95,
        feePer: "ton",
      },
      {
        name: "Automotive Tires",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Truck Tires",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Electronics (TVs, Computers, etc)",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Mattresses or Box Spring",
        fee: 10,
        feePer: "ae",
      },
      {
        name: "Appliance w/ Freon",
        fee: 10,
        feePer: "ae",
      },
    ],
    prohibitedItems: [
      "Toxic or Hazardous Materials",
      "Medical or Biological Waste",
      "Motor Oil / Antifreeze",
      "Gasoline / Kerosene",
      "Highly Flammable Items",
      "Fire Extinguishers",
      "Pool Chemicals",
      "Liquid Wastes",
      "Solvents",
      "Ammunition",
      "Explosives",
      "Military Ordinance",
      "Radioactive Items ",
      "Equipment Tires",
    ],
  },
];

export const addtionalData = {
  prohibitedItems: [
    "Toxic or Hazardous Materials",
    "Medical or Biological Waste",
    "Motor Oil / Antifreeze",
    "Gasoline / Kerosene",
    "Highly Flammable Items",
    "Fire Extinguishers",
    "Pool Chemicals",
    "Liquid Wastes",
    "Solvents",
    "Ammunition",
    "Explosives",
    "Military Ordinance",
    "Radioactive Items ",
    "Equipment Tires",
  ],
};
