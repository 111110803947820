import dayjs from "dayjs";
import {
  addToCartLocalStorage,
  getCartLocalStorage,
  getCompanyFromSession,
  getTaxRate,
  removeCartFromLocalStorage,
  removeCurrentProductLocal,
  removeTaxRateLocal,
  removeZipFromLocal,
} from "./localstorage";
import { v4 as uuidv4 } from "uuid";
import { getCompanyData, orderPlaceApi } from "../api/api";
import { formatM_d_Y } from "./formatter";

export const breadCrumbUpdate = (index) => {
  localStorage.setItem("breadCrumb", index);
};

export const disabledDate = (current, specificDate) => {
  const oneDayLess = specificDate && specificDate.subtract(1, "day");
  return (
    (current && current < dayjs(specificDate && oneDayLess).endOf("day")) ||
    current.day() === 0 ||
    current.day() === 6
  );
};

export const placeOrder = async (card) => {
  const cartData = getCartLocalStorage();
  const tax = getTaxRate();
  const preffTime = new Date(cartData?.serviceAddress?.deliveryDate);
  const preferredTimeRange = cartData?.serviceAddress?.preferredTimeRange;
  const time = preferredTimeRange && preferredTimeRange?.split(" ");
  preferredTimeRange &&
    preffTime.setHours(
      time[time.length - 1] === "AM" ? Number(time[0]) : Number(time[0]) + 12
    );
  const taxCombineRate = tax?.combined_rate ? Number(tax?.combined_rate) : 0;

  const productCharges = {
    id: cartData?.product?.id,
    chargeType: "SKU",
    name: cartData?.product?.name,
    code: cartData?.code,
    description: cartData?.description,
    type: "FIXED",
    quantity: 1,
    value: cartData?.pricingStructure?.price,
    isTaxable: true,
    totalTax: 0,
    taxes: [],
  };

  const filteredAdditional = cartData.additionalFee.filter(
    (d) => d.quantity > 0
  );

  const additional = filteredAdditional.map((v) => {
    const chargeAmount = Number(v?.charge?.value) * Number(v.quantity);
    return {
      chargeType: v?.category,
      description: v?.description,
      id: v?.id,
      name: v?.name,
      type: v?.charge?.type,
      value: Number(v?.charge?.value),
      quantity: Number(v.quantity),
      taxes: [],
      code: "",
      chargeAmount,
      isTaxable: true,
      totalTax: 0,
      chargeSubType: "24077b83-34d9-4bca-901f-16ca6aa6db80",
    };
  });

  const data = {
    city: cartData?.serviceAddress?.city,
    address: cartData?.serviceAddress?.address,
    state: cartData?.serviceAddress?.state,
    zipcode: cartData?.serviceAddress?.zipCode,
    new_address: cartData?.serviceAddress?.formatted_address,
    borough: "",
    neighborhood: "",
    county: cartData?.serviceAddress?.country ?? null,
    location: {
      lat: cartData?.serviceAddress?.latitute,
      lng: cartData?.serviceAddress?.longitude,
    },
    street_no: cartData?.serviceAddress?.street ?? "",
    route: cartData?.serviceAddress?.route ?? "",
    geoPlaceId: cartData?.serviceAddress?.place_id,
    floor: "",
    timezone: {
      dstOffset: 60,
      rawOffset: -480,
      timeZoneId: "America/Los_Angeles",
      timeZoneName: "Pacific Daylight Time",
      clientoffset: -330,
      clienttimezone: "Asia/Calcutta",
    },
    serviceId: "",
    skuId: cartData?.id,
    charges: [productCharges, ...additional],
    placement: cartData?.serviceAddress?.productPlacement ?? null,
    // materialTypes: [
    //   {
    //     id: "6cb31c59-5d9b-4aeb-a3a8-b5e193ccbe20",
    //     name: "Wood",
    //     composition: 1,
    //     category: "MATERIAL",
    //     addedAt: "2023-04-24T10:32:54.050Z",
    //   },
    // ],
    specialinstruction: cartData?.serviceAddress?.instructions ?? null,
    customerDetails: {
      firstname: cartData?.billingInformation?.firstName,
      lastname: cartData?.billingInformation?.lastName,
      streetname: cartData?.billingInformation?.street,
      townname: cartData?.billingInformation?.town,
      state: cartData?.billingInformation?.state,
      zipcode: cartData?.billingInformation?.zipCode,
      billingAndShippingAddressSame: true,
      phone: cartData?.billingInformation?.phoneNumber,
      email: cartData?.billingInformation?.email,
      cardDetails: {
        token: card?.token,
        name: card?.card_data?.name,
        brand: card?.card_data?.brand,
        last4digits: card?.card_data?.last_4,
        expMonth: `${card?.card_data?.exp_month}`,
        expYear: `${card?.card_data?.exp_year}`,
      },
    },
    pickupDate: cartData?.serviceAddress?.pickupDate
      ? formatM_d_Y(cartData?.serviceAddress?.pickupDate)
      : null,
    deliveryDate: formatM_d_Y(cartData?.serviceAddress?.deliveryDate),
    startPreferredDateTime: cartData?.serviceAddress?.startPreferredDateTime,
    endPreferredDateTime: cartData?.serviceAddress?.endPreferredDateTime,
    tax,
  };

  const apiResponse = await orderPlaceApi(data);
  if (apiResponse.status === 200) {
    addToCartLocalStorage({
      ...getCartLocalStorage(),
      orderData: { time: new Date(), ...apiResponse?.data?.data },
    });
    return "Succees";
  } else {
    alert("Some Error Occured");
  }
};
export const getTaxAmmount = (rate, ammount) => Number(rate) * Number(ammount);

export const clearLocalStorage = () => {
  removeCartFromLocalStorage();
  removeTaxRateLocal();
  removeCurrentProductLocal();
  removeZipFromLocal();
};

export const getMerchantId = () => {
  const companyResponse = getCompanyFromSession();
  return companyResponse?.merchantId;
};

export const getTaxDetails = (ammount) => {
  const tax = getTaxRate();
  const taxKeys = Object.keys(tax);
  const filtered = taxKeys.filter((t) => {
    return t.includes("_rate");
  });
  const taxRates = filtered.map((t) => ({
    tax: {
      id: uuidv4(),
      name: t,
      rate: tax[t],
    },
    exemption: {},
    isExempted: true,
    total: getTaxAmmount(tax[t], ammount),
    taxBeforeDiscount: false,
    addedAt: "2023-05-01T10:27:11.753Z",
  }));
  return taxRates;
};

export const formatPhoneNumber = (input) => {
  const phoneNumber = input.replace(/\D/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength > 3 && phoneNumberLength <= 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else if (phoneNumberLength > 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6)}`;
  } else {
    return phoneNumber;
  }
};

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const getTaxPercent = (rate) => Number(rate) * 100;
