import { getMerchantId } from "../actions/commonFunctions";
import { addTokenInLocalStorage, getTokenFromLocalStorage } from "../actions/localstorage";
import { updateTaxRate } from "../redux/reducers/appreducer";
import { GET_METHOD, PAY_POST, POST_METHOD } from "./axiosMethods";


// const companyId = process.env.REACT_APP_COMPANY_ID;
const companyId = localStorage.getItem('companyId');

const getHeaders = (forOrder) => ({
  companyId: companyId,
  token:getTokenFromLocalStorage()
});

//get token
export const getToken = async () => {
  const companyId = localStorage.getItem('companyId');
  const { data } = await GET_METHOD(`companies/ecommerce/${companyId}`);
  addTokenInLocalStorage(data?.data?.token)
  if (data.statusCode === 200) {
    return data;
  }
};

//get zipcode suggestions
export const getZipSuggestions = async (search) => {
  const { data } = await GET_METHOD(`companies/service-zipcodes`, {
    params: {
      mode: "AUTOCOMPLETE",
      q: search,
    },
    headers: getHeaders(),
  });
  if (data.statusCode === 200) {
    const responseToSend = data.data.map((v) => ({ ...v, active: false }));
    return responseToSend;
  } else {
    return [];
  }
};

//get skus by zipcode
export const getSKUsBYCode = async (zipcode) => {
  const { data } = await GET_METHOD(`companies/ecommerce/skus`, {
    params: {
      pageNo: 1,
      zipcode: Number(zipcode),
    },
    headers: getHeaders(),
  });
  if (data.statusCode === 200) {
    return data?.data;
  } else {
    return [];
  }
};

//get taxes function
//get skus by zipcode
export const getTaxRate = async (
  zipcode,
  city,
  state,
  country,
  dispatch,
  street
) => {
  const { data } = await GET_METHOD(`salestax`, {
    params: { zip: zipcode, city, state, country, street },
  });
  dispatch(updateTaxRate(data));
};

//get charges
export const getCharges = async () => {
  const { data } = await GET_METHOD(`companies/ecommerce/charges`, {
    params: {
      pageNo: 1,
    },
    headers: getHeaders(),
  });
  if (data.statusCode === 200) {
    return data?.data;
  } else {
    return [];
  }
};

export const orderPlaceApi = async (orderdata) => {
  const response = await POST_METHOD("orders/ecommerce", orderdata, {
    headers: getHeaders(true),
  });
  return response;
};

export const getCompanyData = async () => {
  const companyId = localStorage.getItem('companyId');
  const { data } = await GET_METHOD(`companies/ecommerce/${companyId}`);
  if (data.statusCode === 200) {
    return data?.data;
  } else {
    return [];
  }
};
