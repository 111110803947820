import React, { useEffect } from "react";
import s from "./Header.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompanyData } from "../../../api/api";
import {
  addCompanyToSession,
  getCompanyFromSession,
} from "../../../actions/localstorage";
import { getMerchantId } from "../../../actions/commonFunctions";
import Logo from '../../../assets/images/logo.png';

const Header = ({ otherClasses }) => {
  const navigate = useNavigate();
  let curLoc = useLocation();

  const companyData = JSON.parse(sessionStorage.getItem('companyData'));

  const titleMap = [
    { path: "/", title: "Home" },
    { path: "/terms-and-conditions", title: "Terms & Conditions" },
    { path: "/thanks", title: "Thanks" },
    { path: "/payment", title: "Billing Info" },
    { path: "/service-details", title: "Service Details" },
    { path: "/additional-items", title: "Additional Items" },
    { path: "/product", title: "Select Your Product" },
  ];

  useEffect(() => {
    getData();
  }, [curLoc]);

  const getData = async () => {
    const companyResponse = await getCompanyData();
    const curTitle = titleMap.find((item) => item.path === curLoc.pathname);
    if (curTitle && curTitle.title) {
      document.title = companyResponse?.companyName ? curTitle.title + ` | ${companyResponse.companyName}` : curTitle.title;
    }
    await addCompanyToSession(companyResponse);
    getMerchantId();
  };

  const toHome = () => {
    navigate("/");
  };

  const headerClasses = `${
    curLoc.pathname === "/"
      ? s.AbsoluteClass
      : curLoc.pathname !== "/thanks" &&
        curLoc.pathname !== "/terms-and-conditions" &&
        s.HideOnMob
  } ${s.Header} ${otherClasses && otherClasses}`;

  return (
    <>
      <header className={headerClasses}>
        <img
          className={s.HeaderImg}
          onClick={toHome}
          alt="logo"
          height={38}
          src={companyData ? companyData?.logoUrl : Logo}
        />
      </header>
    </>
  );
};
export default Header;
