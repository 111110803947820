import React, { useState } from "react";
import s from "./login.module.css";

const TermsAndCondition = () => {
  return (
    <>
      <section className={s.SectionDefault}>
        <div className={`container p-3 ${s.Terms}`}>
          <h1 className="text-center">Terms & Conditions</h1>
          <h4>Introduction:</h4>
          <div>
            Welcome to our Dumpster website. These terms and conditions govern
            your use of our website; by using our website, you accept these
            terms and conditions in full. If you disagree with these terms and
            conditions or any part of these terms and conditions, you must not
            use our website.
          </div>
          <h4 className="mt-3">License to use website:</h4>
          <div>
            Unless otherwise stated, we or our licensors own the intellectual
            property rights in the website and material on the website. Subject
            to the license below, all these intellectual property rights are
            reserved.
          </div>
          <div>
            You may view, download for caching purposes only, and print pages
            from the website for your own personal use, subject to the
            restrictions set out below and elsewhere in these terms and
            conditions.
          </div>
          <h4 className="mt-3">You must not:</h4>
          <ul>
            <li>
              republish material from this website (including republication on
              another website)
            </li>
            <li>sell, rent or sub-license material from the websit</li>
            <li>show any material from the website in publi</li>
            <li>
              reproduce, duplicate, copy or otherwise exploit material on our
              website for a commercial purpos edit or otherwise modify any
              material on the website.
            </li>
          </ul>
          <h4 className="mt-3">Acceptable use</h4>
          <ul>
            <li>
              You must not use our website in any way that causes, or may cause,
              damage to the website or impairment of the availability or
              accessibility of the website; or in any way which is unlawful,
              illegal, fraudulent, or harmful, or in connection with any
              unlawful, illegal, fraudulent, or harmful purpose or activity.
            </li>
            <li>
              You must not use our website in any way that causes, or may cause,
              damage to the website or impairment of the availability or
              accessibility of the website; or in any way which is unlawful,
              illegal, fraudulent, or harmful, or in connection with any
              unlawful, illegal, fraudulent, or harmful purpose or activity.
            </li>
            <li>
              You must not use our website to copy, store, host, transmit, send,
              use, publish or distribute any material which consists of (or is
              linked to) spyware, computer virus, Trojan horse, worm, keystroke
              logger, rootkit, or other malicious computer software.
            </li>
            <li>
              You must not use our website for any purposes related to marketing
              without our express written consent.
            </li>
          </ul>
          <h4 className="mt-3">Limitations of liability</h4>
          <div>
            Nothing in these terms and conditions will limit or exclude our or
            your liability for death or personal injury resulting from
            negligence; limit or exclude our or your liability for fraud or
            fraudulent misrepresentation; limit any of our or your liabilities
            in any way that is not permitted under applicable law; or exclude
            any of our or your liabilities that may not be excluded under
            applicable law.
          </div>
          <div>
            The limitations and exclusions of liability set out in these terms
            and conditions: (a) are subject to the preceding paragraph; and (b)
            govern all liabilities arising under these terms and conditions or
            relating to the subject matter of these terms and conditions,
            including liabilities arising in contract, tort (including
            negligence) and for breach of statutory duty, except to the extent
            expressly provided otherwise in these terms and conditions.
          </div>
          <h4 className="mt-3">Indemnity</h4>
          <div>
            You agree to indemnify us, and our officers, directors, employees,
            agents, and affiliates, from any claims, demands, damages,
            liabilities, costs or expenses, including reasonable attorneys'
            fees, that arise from your use or misuse of our website.
          </div>
          <h4 className="mt-3">Variation</h4>
          <div>
            We may revise these terms and conditions from time-to-time. The
            revised terms and conditions shall apply to the use of our website
            from the date of publication of the revised terms and conditions on
            our website.
          </div>
          <h4 className="mt-3">Entire agreement</h4>
          <div>
            These terms and conditions, together with our privacy policy,
            constitute the entire agreement between you and us in relation to
            your use of our website and supersede all previous agreements in
            respect of your use of this website.
          </div>
          <h4 className="mt-3">Governing Law</h4>
          <div>
            These terms and conditions will be governed by and construed in
            accordance with the laws of [insert your state or country], and any
            disputes relating to these terms and conditions will be subject to
            the exclusive jurisdiction of the courts of [insert your state or
            country].
          </div>{" "}
        </div>
      </section>
    </>
  );
};
export default TermsAndCondition;
