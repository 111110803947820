import React, { useCallback, useEffect, useRef } from "react";
import { loadPayengine, payengineStyles } from "payengine";
import s from "./Payment.module.css";
import { getMerchantId } from "../../../actions/commonFunctions";

function MyComponent({ engineRef, setBtnDisable, cardErr }) {
  useEffect(() => {
    // Load Payengine on first render only
    loadPayengine({
      publicKey: "pk_test_Hw7bV443o0gMMtbTtKHMedhRqLCGDWJG",
    }).then((pe) => {
      setBtnDisable(false);
    });
  }, []);

  // const addEvents = useCallback((node) => {
  //   console.log("DSF", node);
  //   if (node !== null) {
  //     node.addEventListener("stepChange", function (e) {
  //       console.log("step changed", e.detail);
  //     });
  //   }
  // });

  return (
    <>
      <pay-engine
        id="creditcardform"
        ref={engineRef}
        merchant-id={getMerchantId()}
        type="creditcardform"
        styleModule={payengineStyles(s)}
      ></pay-engine>
      {cardErr && <div className="invalid-feedback">{cardErr}</div>}
    </>
  );
}
export default MyComponent;
