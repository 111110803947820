import { Router } from "./config/router";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./App.scss";
import "./assets/fonts/Pangram/stylesheet.css";
import { ConfigProvider } from "antd";
import { antdTheme } from "./data/constants";

function App() {
  return (
    <ConfigProvider theme={antdTheme}>
      <Provider store={store}>
        <Router />
      </Provider>
    </ConfigProvider>
  );
}

export default App;
