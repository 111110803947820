export const primaryColor = "#01aef0";
export const antdTheme = {
  token: {
    colorPrimary: primaryColor,
  },
};

export const materialOptions = [
  { label: "Wood", value: "Wood" },
  { label: "Concrete", value: "Concrete" },
  { label: "Dirt", value: "Dirt" },
  { label: "Recycling", value: "Recycling" },
  { label: "Wood", value: "Wood" },
  { label: "Concrete", value: "Concrete" },
  { label: "Dirt", value: "Dirt" },
  { label: "Recycling", value: "Recycling" },
  { label: "Wood", value: "Wood" },
  { label: "Concrete", value: "Concrete" },
  { label: "Dirt", value: "Dirt" },
  { label: "Recycling", value: "Recycling" },
];

export const options = [
  { label: "On Street", value: "On Street" },
  { label: "In Driveway", value: "In Driveway" },
];

export const dateOptions = [
  { label: "6 : 00 AM", value: "6 : 00 AM" },
  { label: "7 : 00 AM", value: "7 : 00 AM" },
  { label: "8 : 00 AM", value: "8 : 00 AM" },
  { label: "9 : 00 AM", value: "9 : 00 AM" },
  { label: "10 : 00 AM", value: "10 : 00 AM" },
  { label: "11 : 00 AM", value: "11 : 00 AM" },
  { label: "12 : 00 PM", value: "12 : 00 PM" },
  { label: "1 : 00 PM", value: "1 : 00 PM" },
  { label: "2 : 00 PM", value: "2 : 00 PM" },
  { label: "3 : 00 PM", value: "3 : 00 PM" },
  { label: "4 : 00 PM", value: "4 : 00 PM" },
  { label: "5 : 00 PM", value: "5 : 00 PM" },
];
