import React, { useEffect, useState } from "react";

import { Formik, Form } from "formik";
import s from "./login.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addZipData,
  breadCrumbChange,
} from "../../../redux/reducers/appreducer";
import { CustomDateInput, ZipCodeInput } from "../../common";
import { zipCodeValidation } from "../../../actions/validations";
import { addZipToLocal } from "../../../actions/localstorage";
import { clearLocalStorage } from "../../../actions/commonFunctions";

const LoginComponent = () => {
  const navigate = useNavigate();
  const diapatch = useDispatch();
  const companyData = JSON.parse(sessionStorage.getItem('companyData'));

  const [suggestions, setSuggestions] = useState([]);

  const [data] = useState({
    zipCode: "",
    date: "",
  });

  const handleSubmit = (values) => {
    addZipToLocal(values);
    navigate("/product");
    diapatch(breadCrumbChange(-1));
    diapatch(addZipData(values));
  };

  useEffect(() => {
    clearLocalStorage();
  }, []);

  return (
    <>
      <div className={s.SectionDefault}>
        <div className={`row justify-content-between ${s.Container}`}>
          <div className={`col-sm-6 ${s.HeadingWrapper}`}>
            <div className={s.HomeHeadingTitle}>
              Local, Affordable, Dependable
            </div>
            <div className={s.HomeDescription}>
              Dumpster Rentals of Northwest Arkansas & Fort Smith
            </div>
          </div>
          <div className={`rounded border col-sm-5 ${s.CardWrapper}`}>
            <div className={s.HeadWrapper}>
              <div className={s.HeadWrapperTitle}>Dumpster Rental</div>
            </div>
            <div className={s.HeadWrapperInner}>
              <div className={s.HeadWrapperInnerWrap}>
                <Formik
                  initialValues={data}
                  validate={(v) => zipCodeValidation(v, suggestions)}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched }) => (
                    <Form className={s.HeadWrapperFormGrid}>
                      <div className={s.HomeScreenInputContainer}>
                        <ZipCodeInput
                          setSuggestions={setSuggestions}
                          suggestions={suggestions}
                          label={"Service Location"}
                          placeholder={"Zip Code..."}
                          type={"number"}
                          name={"zipCode"}
                          dark
                        />
                      </div>
                      <div className={s.HomeScreenInputContainer}>
                        <CustomDateInput
                          label={"Select Service Date"}
                          placeholder={"Service Date"}
                          name={"date"}
                          dark
                        />
                      </div>
                      <div className={s.SubmitContainer}>
                        <button
                          type="submit"
                          className={`btn btn-primary ${s.SubmitButton}`}
                        >
                          Get Rates
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginComponent;
