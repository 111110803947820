import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleFilled } from "@ant-design/icons";

//import functions
import {
  addProductToCart,
  breadCrumbChange,
} from "../../../redux/reducers/appreducer";
import {
  CustomDateInput,
  CustomInput,
  CustomTimeInput,
  InputForMap,
  SelectForAdditional,
  ZipCodeInput,
} from "../../common";
import { serviceDetailsValidation } from "../../../actions/validations";
import { Form, Formik } from "formik";
import {
  addTaxRate,
  addToCartLocalStorage,
  getCartLocalStorage,
  getZipFromLocal,
} from "../../../actions/localstorage";

//import components
import SelectWithCheck from "../../common/inputs/CheckBoxSelect";
import { options } from "../../../data/constants";
import ProductSummary from "../../layouts/serviceSDetails/ProductSummary";
import { getSKUsBYCode } from "../../../api/api";
import { TimePicker } from "antd";
const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [allSkUS, setAllSKUS] = useState([]);

  const [startPreferredDateTime, setStartPreferredDateTime] = useState();
  const [endPreferredDateTime, setEndPreferredDateTime] = useState();
  const zipCodeData = getZipFromLocal();
  const cartData = getCartLocalStorage();
  const {
    serviceAddress: {
      address,
      city,
      state,
      zipCode,
      productPlacement,
      deliveryDate,
      pickupDate,
      preferredTimeRange,
      instructions,
      formatted_address,
      place_id,
      town,
      street,
      route,
      longitude,
      latitute,
    },
  } = cartData || { serviceAddress: {} };

  const taxrate = useSelector((state) => state.taxrate);

  const [suggestions, setSuggestions] = useState([]);

  const [data] = useState({
    address,
    city,
    state,
    zipCode: zipCode ? zipCode : zipCodeData?.zipCode,
    productPlacement: productPlacement ? productPlacement : null,
    instructions: instructions ? instructions : null,
    deliveryDate: deliveryDate
      ? dayjs(deliveryDate)
      : zipCodeData?.date && dayjs(zipCodeData?.date),
    pickupDate: pickupDate ? dayjs(pickupDate) : null,
    preferredTimeRange: preferredTimeRange ? preferredTimeRange : null,
    formatted_address: formatted_address ? formatted_address : null,
    place_id,
    town,
    street,
    route,
    longitude,
    latitute,
  });

  useEffect(() => {
    dispatch(breadCrumbChange(2));
  }, []);

  const handleSubmit = (values) => {
    values.startPreferredDateTime = startPreferredDateTime
    values.endPreferredDateTime = endPreferredDateTime
    const data = {
      ...getCartLocalStorage(),
      serviceAddress: values,
    };
    addToCartLocalStorage(data);
    dispatch(addProductToCart(data));
    navigate("/payment");
    addTaxRate(taxrate);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await getSKUsBYCode(zipCodeData?.zipCode);
    const sorted = response.sort(function (a, b) {
      return (
        Number(a?.product?.name.split(" ")[0]) -
        Number(b?.product?.name.split(" ")[0])
      );
    });
    setAllSKUS(sorted);
  };

  return (
    <>
      <Formik
        initialValues={data}
        validate={(v) => serviceDetailsValidation(v, suggestions)}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="container">
              <div className="form-header mt-3">
                <div className="form-header-col">
                  <div className="form-header-col-inner">
                    <h1 className="form-header-title">Service Details </h1>
                    <p className="form-header-subtitle">
                      Enter the service and product information for your day of
                      service
                    </p>
                  </div>
                </div>
                <div className="form-header-col"></div>
              </div>

              <div className="row">
                <ProductSummary allSkUS={allSkUS} data={cartData} />
                <div className="col-md-8">
                  <div className="d-flex align-center justify-content-between">
                    <div className="form-title">Service Address*</div>
                    <div className="form-required-label">*Required</div>
                  </div>
                  <div>
                    <InputForMap
                      name={"address"}
                      label={"Street Address"}
                      placeholder={"1234 Street Avenue"}
                      getTax = {false}
                    />
                    <div className="row">
                      <div className="col-md-6">
                        <CustomInput
                          name={"city"}
                          label={"City"}
                          placeholder={""}
                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput name="state" label={"State"} />
                      </div>
                      <div className="col-md-3">
                        {/* <CustomInput
                          name={"zipCode"}
                          type={"number"}
                          label={"Zip Code"}
                          placeholder={"Zip Code..."}
                        /> */}
                        <ZipCodeInput
                          setSuggestions={setSuggestions}
                          suggestions={suggestions}
                          label={"Zip Code"}
                          placeholder={"Zip Code..."}
                          type={"number"}
                          name={"zipCode"}
                        />
                      </div>
                    </div>

                    <div className="form-title">Date & Time*</div>

                    <div className="row">
                      <div className="col-md-6">
                        <CustomDateInput
                          placeholder={"Select..."}
                          label={"Delivery Date"}
                          name={"deliveryDate"}
                        />
                      </div>
                      <div className="col-md-6">
                        <CustomDateInput
                          placeholder={"Select..."}
                          label={"Pickup Date (optional)"}
                          name={"pickupDate"}
                          deliveryDate={true}
                        />
                      </div>
                      {/* <div style={{ color: "#808080", fontWeight: "700", fontSize: "14px", lineHeight: "normal", marginBottom: "10px" }}>
                        Preferred Time Range (optional)
                      </div> */}
                      {/* <div className="col-md-6">
                        <CustomTimeInput
                          label="Preferred Time Range (optional)"
                          placeholder="Select..."
                          name="endPreferredDateTime"
                        />
                      </div> */}
                      <div>
                        <div style={{ color: "#808080", fontWeight: "700", fontSize: "14px", lineHeight: "normal", marginBottom: "10px" }}>
                          Preferred Time Range (optional)
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: '15px'
                          }}
                        >
                          <TimePicker
                            use12Hours
                            className="preffered-time form-control"
                            format="HH:mm A"
                            placeholder={"Enter Start Time"}
                            inputReadOnly={false}
                            suffixIcon={<div style={{ display: "none" }} />}
                            onChange={(time, timeString) =>
                              setStartPreferredDateTime(time)
                            }
                            style={{ width: "100%", marginRight: "10px" }}
                            allowClear={false}
                          />
                          <TimePicker
                            use12Hours
                            className="preffered-time form-control"
                            format="HH:mm A"
                            placeholder={"Enter End Time"}
                            inputReadOnly={false}
                            suffixIcon={<div style={{ display: "none" }} />}
                            onChange={(time, timeString) =>
                              setEndPreferredDateTime(time)
                            }
                            style={{ width: "100%" }}
                            allowClear={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-title" style={{marginTop:"12px"}}>Additional Details</div>
                    <div className="row">
                      <SelectForAdditional
                        mainClassName={"col-md-6"}
                        name="productPlacement"
                        label={"Product Placement"}
                        options={options}
                      />
                    </div>
                    <CustomInput
                      tooltipText={
                        <InfoCircleFilled
                          style={{
                            color: "#808080",
                            fontSize: 14,
                            marginLeft: 5,
                          }}
                        />
                      }
                      clickTrigger
                      tooltipTitle="Anything else you want to let us know about the day of your delivery?"
                      name={"instructions"}
                      notDark
                      label={"Special Instructions"}
                      textarea
                      placeholder={"Enter..."}
                    />
                  </div>
                  <div className="form-footer">
                    <button type="submit" className="btn btn-primary">
                      Continue to Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default Index;
