export const addZipToLocal = (values) => {
  localStorage.setItem("addZipData", JSON.stringify(values));
};

export const getZipFromLocal = () =>
  JSON.parse(localStorage.getItem("addZipData"));

export const addCurrentproduct = (data) => {
  localStorage.setItem("currentProduct", JSON.stringify(data));
};

export const getCurrentproduct = () =>
  JSON.parse(localStorage.getItem("currentProduct"));

export const addToCartLocalStorage = (data) => {
  localStorage.setItem("my_cart", JSON.stringify(data));
};

export const getCartLocalStorage = () =>
  JSON.parse(localStorage.getItem("my_cart"));

export const removeCartFromLocalStorage = () => {
  localStorage.removeItem("my_cart");
};
export const removeTaxRateLocal = () => {
  localStorage.removeItem("taxrate");
};
export const removeCurrentProductLocal = () => {
  localStorage.removeItem("currentProduct");
};
export const addTaxRate = (rate) => {
  rate && localStorage.setItem("taxrate", JSON.stringify(rate));
};
export const removeZipFromLocal = () => {
  localStorage.removeItem("addZipData");
};

export const getTaxRate = () => {
  const taxRates = localStorage.getItem("taxrate");
  return taxRates ? JSON.parse(taxRates) : {};
};

export const addCompanyToSession = (company) => {
  sessionStorage.setItem("companyData", JSON.stringify(company));
};

export const getCompanyFromSession = () =>
  JSON.parse(sessionStorage.getItem("companyData"));

export const addTokenInLocalStorage = (token) => {
  localStorage.setItem("ozark__token", token);
}
export const getTokenFromLocalStorage = () =>
localStorage.getItem("ozark__token");
